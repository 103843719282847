// src/components/ProcedureList.tsx
import {
  List,
  Datagrid,
  TextField,
  DateField,
  DateInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  ListProps,
} from "react-admin";

const ProcedureFilters = [
  <DateInput key="date" source="selectedDate" label="Select Date" alwaysOn />,
];

export const ProcedureList = (props: ListProps) => (
  <List
    {...props}
    filters={ProcedureFilters}
    filterDefaultValues={{
      selectedDate: new Date().toISOString().split("T")[0],
    }}
  >
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <DateField source="began_at" showTime sortable={false} />
      <DateField source="ended_at" showTime sortable={false} />
      <TextField source="caregiver_name" sortable={false} label="Tablet Name" />
      <TextField source="activity_kind" sortable={false} />
      <TextField source="session_kind" sortable={false} />
      <TextField source="participant_name" sortable={false} />
      <TextField source="activity_detail" sortable={false} label="Code" />
      <TextField source="activity_title" sortable={false} />
      <ArrayField source="activity_categories" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);
