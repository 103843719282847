import { buildQuery } from "ra-data-graphql-simple";
import buildDataProvider from "ra-data-graphql-simple";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import { GRAPHQL_ENDPOINT } from "../config";

const client = new ApolloClient({
  uri: GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${
      localStorage.getItem("auth")
        ? JSON.parse(localStorage.getItem("auth")!).token
        : ""
    }`,
  },
});

const procedureListQuery = gql`
  query SearchProcedures($input: ProcedureSearchInput!) {
    searchProcedures(input: $input) {
      session_id
      began_at
      ended_at
      caregiver_id
      caregiver_name
      activity_kind
      session_kind
      participant_id
      participant_name
      activity_detail
      activity_title
      activity_categories {
        id
        name
      }
    }
  }
`;

const organizationGetOneQuery = gql`
  query OrganizationQuery($id: UUID!) {
    organization(id: $id) {
      id
      name
      externalId
      organizationPatients {
        nodes {
          patient {
            fhirId
            user {
              id
              name
            }
          }
        }
      }
      organizationPractitioners {
        nodes {
          practitioner {
            id
            name
          }
        }
      }
    }
  }
`;

const organizationListQuery = gql`
  query OrganizationsQuery($first: Int, $offset: Int) {
    organizations(first: $first, offset: $offset) {
      nodes {
        id
        name
        externalId
      }
      totalCount
    }
  }
`;

const customBuildQuery =
  (introspectionResults: any) =>
  (fetchType: string, resource: string, params: any) => {
    if (resource === "Procedure" && fetchType === "GET_LIST") {
      // Use the selected date from filters, or default to today
      const selectedDate =
        params.filter?.selectedDate || new Date().toISOString().split("T")[0];

      // Create start and end of the selected date
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      const result = {
        query: procedureListQuery,
        variables: {
          input: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
          first: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
        },
        parseResponse: (response: any) => {
          const data = response.data.searchProcedures.map((procedure: any) => ({
            ...procedure,
            id: procedure.session_id, // Map session_id to id
          }));

          return {
            data,
            total: data.length,
          };
        },
      };
      return result;
    } else if (resource === "Organization" && fetchType === "GET_LIST") {
      return {
        query: organizationListQuery,
        variables: {
          first: params.pagination.perPage,
          offset: (params.pagination.page - 1) * params.pagination.perPage,
        },
        parseResponse: (response: any) => ({
          data: response.data.organizations.nodes,
          total: response.data.organizations.totalCount,
        }),
      };
    } else if (resource === "Organization" && fetchType === "GET_ONE") {
      return {
        query: organizationGetOneQuery,
        variables: { id: params.id },
        parseResponse: (response: any) => {
          const organization = response.data.organization;

          // Flatten practitioners by extracting the nested practitioner object
          const practitioners =
            organization.organizationPractitioners.nodes.map(
              (node: any) => node.practitioner
            );

          // Flatten patients by extracting the nested patient object
          const patients = organization.organizationPatients.nodes.map(
            (node: any) => ({
              source: node.patient.fhirId ? "FHIR API" : "m4c app",
              fhirId: node.patient.fhirId,
              ...node.patient.user,
            })
          );

          return {
            data: {
              ...organization,
              practitioners,
              patients,
            },
          };
        },
      };
    }
    return buildQuery(introspectionResults)(fetchType, resource, params);
  };

export default buildDataProvider({ client, buildQuery: customBuildQuery });
