import React, { useState } from "react";
import { useNotify, useRedirect, useAuthProvider } from "react-admin";
import { Box, Button, TextField, Typography } from "@mui/material";

// Utility function to check password strength
const isStrongPassword = (password: string): boolean => {
  const minLength = 16;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);
  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
};

export const ChangePasswordScreen: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const notify = useNotify();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error on new submit

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!isStrongPassword(newPassword)) {
      setError(
        "Password must be at least 16 characters long, and include an uppercase letter, a lowercase letter, a number, and a special character (@$!%*?&)"
      );
      return;
    }

    try {
      await authProvider?.changePassword({ oldPassword, newPassword });
      notify("Password changed successfully", { type: "success" });
      redirect("/");
    } catch (error) {
      console.log("EXCEPT", error);
      const message =
        error instanceof Error ? error.message : "Error changing password";
      notify(message, { type: "error" });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 400, margin: "auto", mt: 5 }}
    >
      <Typography variant="h5" mb={2}>
        Change Password
      </Typography>
      <TextField
        label="Old Password"
        type="password"
        fullWidth
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        label="New Password"
        type="password"
        fullWidth
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        label="Confirm New Password"
        type="password"
        fullWidth
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        margin="normal"
        required
      />
      {error && (
        <Typography color="error" variant="body2" mt={1}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Change Password
      </Button>
    </Box>
  );
};
