import { Menu, MenuProps } from "react-admin";
import { Business, ListAlt, Password } from "@mui/icons-material";

export const CustomMenu = (props: MenuProps) => (
  <Menu {...props}>
    <Menu.Item
      to="/Organization"
      primaryText="Organizations"
      leftIcon={<Business />}
    />
    <Menu.Item
      to="/Procedure"
      primaryText="Procedures"
      leftIcon={<ListAlt />}
    />
    <Menu.Item
      to="/change-password"
      primaryText="Change Password"
      leftIcon={<Password />}
    />
  </Menu>
);
