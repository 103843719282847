// src/auth/authProvider.ts
import { AuthProvider } from "react-admin";
import { GRAPHQL_ENDPOINT } from "../config";
import { CHANGE_PASSWORD, LOGIN_MUTATION } from "./mutations";

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: LOGIN_MUTATION,
        variables: {
          email: username,
          password,
        },
      }),
    });

    const result = await response.json();

    const loginData = result.data?.loginUser;

    if (loginData?.jwtToken) {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          token: loginData.jwtToken,
          status: loginData.loginStatus,
          email: username,
        })
      );
      return Promise.resolve();
    }

    throw new Error("Login failed");
  },

  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  changePassword: async ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      return Promise.reject("Not authenticated");
    }

    const { email } = JSON.parse(auth);

    if (!email) {
      return Promise.reject("User email not found");
    }

    // Step 1: Validate old password by logging in
    const loginResponse = await fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: LOGIN_MUTATION,
        variables: {
          email: email, // Assuming status holds the email/username
          password: oldPassword,
        },
      }),
    });

    const loginResult = await loginResponse.json();

    const loginData = loginResult.data?.loginUser;
    if (!loginData.jwtToken) {
      throw new Error("Old password is incorrect");
    }

    // Step 2: Proceed with password reset if old password is valid
    const response = await fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginData?.jwtToken}`,
      },
      body: JSON.stringify({
        query: CHANGE_PASSWORD,
        variables: {
          password: newPassword,
        },
      }),
    });

    const result = await response.json();

    if (response.status < 200 || response.status >= 300 || result.errors) {
      throw new Error(
        result.errors?.[0]?.message || "Failed to change password"
      );
    }

    const changePassword = result.data?.changePassword;

    if (changePassword?.jwtToken) {
      // Update token in localStorage
      localStorage.setItem(
        "auth",
        JSON.stringify({
          token: changePassword.jwtToken,
          status: "authenticated",
          email: email,
        })
      );
      return Promise.resolve();
    }

    throw new Error("Password change failed");
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    const auth = localStorage.getItem("auth");
    return auth ? Promise.resolve() : Promise.reject();
  },

  getPermissions: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const { status } = JSON.parse(auth);
      return Promise.resolve(status);
    }
    return Promise.reject();
  },

  getIdentity: () => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      return Promise.reject();
    }
    // You might want to fetch user details here using the token
    return Promise.resolve({
      id: "user",
      fullName: "Manager",
    });
  },
};
