// src/App.tsx
import { Admin, Layout, Login, Resource, CustomRoutes } from "react-admin";
import { createTheme } from "@mui/material/styles";
import { Route } from "react-router-dom";
import { authProvider } from "./auth/authProvider";
import { ChangePasswordScreen } from "./auth/ChangePasswordScreen";
import { CustomMenu } from "./layout/CustomMenu";
import { OrganizationList } from "./components/OrganizationList";
import { ProcedureList } from "./components/ProcedureList";

import { OrganizationShow } from "./components/OrganizationShow";
import dataProvider from "./providers/dataProvider";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const CustomLayout = (props: any) => <Layout {...props} menu={CustomMenu} />;

const App = () => (
  <Admin
    theme={darkTheme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={Login}
    layout={CustomLayout}
  >
    <Resource
      name="Organization"
      list={OrganizationList}
      show={OrganizationShow}
    />
    <Resource name="Procedure" list={ProcedureList} />
    <CustomRoutes>
      <Route path="/change-password" element={<ChangePasswordScreen />} />
    </CustomRoutes>
  </Admin>
);

export default App;
