// src/auth/mutations.ts
export const LOGIN_MUTATION = `
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      jwtToken
      loginStatus
    }
  }
`;

export const CHANGE_PASSWORD = `
  mutation changePassword($password: String!) {
    changePassword(input: { newPassword: $password }) {
      jwtToken
    }
  }
`;
